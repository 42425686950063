import { animated, config, useSpring } from "react-spring";

import logoUrl from "./farmalogo.png";

export default function TermsOfUse() {
  const springLeft = useSpring({
    to: {
      x: 1,
      opacity: 1,
      transform: "translate3d(0px,0,0)",
    },
    from: {
      x: 0,
      transform: "translate3d(-200px,0,0)",
      opacity: 0,
    },
    delay: 500,
    config: config.gentle,
  });

  return (
    <div className="sm:px-8 sm:py-8 lg:px-32 lg:py-16 md:px-12 md:py-12 text-lg bg-section1 text-gray">
      <div className="w-full">
        <div className="flex-none w-full align-middle justify-center">
          <animated.div className="flex-none w-full align-middle justify-center" style={springLeft}>
            <img src={logoUrl}
              alt="Royal Advice logo"
              width={164}
              height={32} />
          </animated.div>
        </div>
        <br></br>
        <div className="flex">
          <h3>
            TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS CANDIDATO
          </h3>
        </div>
      </div>
      <p>&nbsp;</p>
      <p className="text-justify">
        Este documento visa registrar a manifestação livre, informada e
        inequívoca pela qual o <strong>CANDIDATO </strong>concorrendo à vaga de
        emprego na <strong>G M COMÉRCIO DE MEDICAMENTOS LTDA</strong>{" "}
        concorda com o tratamento de seus dados pessoais para finalidade
        específica, em conformidade com a Lei nº 13.709 (Lei Geral de Proteção
        de Dados Pessoais – LGPD).
      </p>
      <p className="text-justify">
        Ao assinar o presente termo, o <strong>CANDIDATO</strong> consente e
        concorda que a <strong>G M COMÉRCIO DE MEDICAMENTOS</strong> tome decisões referentes ao
        tratamento de seus dados pessoais, bem como realize o tratamento destes,
        envolvendo operações como a coleta, produção, recepção, classificação,
        utilização, acesso, reprodução, transmissão, distribuição,
        processamento, arquivamento, armazenamento, eliminação, avaliação ou
        controle da informação, modificação, comunicação, transferência, difusão
        ou extração.
      </p>
      {/* <p className="text-justify">
        Este termo está vinculado ao Aviso de Privacidade de Candidatos de
        Emprego da <strong>G M COMÉRCIO DE MEDICAMENTOS</strong>, que se encontra disponível
        para acesso no website da <strong>G M COMÉRCIO DE MEDICAMENTOS </strong>
        <a
          href="https://royaladvice.com.br"
          className="text-white hover:text-gray-300"
        >
          (https://royaladvice.com.br)
        </a>
        .
      </p> */}
      <br></br>
      <p>
        <strong>DOS DADOS PESSOAIS</strong>
      </p>
      <p>
        Os dados pessoais coletados do <strong>CANDIDATO</strong> são:
      </p>
      <ol>
        <li>nome completo;</li>
        <li>data de nascimento;</li>
        <li>número de telefone e contato WhatsApp;</li>
        <li>endereço de e-mail pessoal;</li>
        <li>sexo</li>
        <li>currículo;</li>
      </ol>
      <p>
        O tratamento dos dados pessoais poderá ser realizado para possibilitar o
        processo seletivo da vaga, possível estabelecimento de vínculo
        contratual e todos os atos que decorram da eventual relação de trabalho
        a ser firmada entre o <strong>CANDIDATO </strong>e a{" "}
        <strong>G M COMÉRCIO DE MEDICAMENTOS</strong>.&nbsp;
      </p>
      <br></br>
      <p>
        <strong>DO COMPARTILHAMENTO E SEGURANÇA DOS DADOS</strong>
      </p>
      <p className="text-justify">
        A <strong>G M COMÉRCIO DE MEDICAMENTOS</strong> fica desde já autorizado a compartilhar
        os dados pessoais do <strong>CANDIDATO</strong> com sua área interna de
        gestão de pessoas, com o gestor da área que deu origem à vaga, com
        empresas de recrutamento e seleção, com redes sociais de negócios e com
        empresas terceiras que fornecem licença de software para armazenamento e
        gestão de dados.
      </p>
      <p className="text-justify">
        A <strong>G M COMÉRCIO DE MEDICAMENTOS </strong>responsabiliza-se pela manutenção de
        medidas de segurança, técnicas e administrativas aptas a proteger os
        dados pessoais de acessos não autorizados e de situações acidentais ou
        ilícitas de destruição, perda, alteração, comunicação ou qualquer forma
        de tratamento inadequado ou ilícito.
      </p>
      <p className="text-justify">
        Em conformidade ao artigo 48 da Lei nº 13.709, a{" "}
        <strong>G M COMÉRCIO DE MEDICAMENTOS</strong> comunicará ao <strong>CANDIDATO</strong> e
        à Autoridade Nacional de Proteção de Dados – ANPD a ocorrência de
        incidente de segurança que possa acarretar risco ou dano relevante ao{" "}
        <strong>CANDIDATO</strong>.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>DO TÉRMINO DO TRATAMENTO DOS DADOS</strong>
      </p>
      <p className="text-justify">
        A <strong>G M COMÉRCIO DE MEDICAMENTOS</strong> poderá manter e tratar os dados pessoais
        do <strong>CANDIDATO</strong> durante todo o período em que os mesmos
        forem pertinentes ao alcance das finalidades listadas neste termo ou em
        razão de obrigação legal.
      </p>
      <p className="text-justify">
        O <strong>CANDIDATO</strong> poderá solicitar a{" "}
        <strong>G M COMÉRCIO DE MEDICAMENTOS</strong>, a qualquer momento, que sejam eliminados
        seus dados pessoais por meio do e-mail contato@royaladvice.com.br, sendo
        que sua solicitação será considerada de acordo com as leis aplicáveis.
      </p>
      <br></br>
      <p>
        <strong>DOS DIREITOS DO CANDIDATO</strong>
      </p>
      <p>
        O <strong>CANDIDATO</strong> tem direito a obter da{" "}
        <strong>G M COMÉRCIO DE MEDICAMENTOS</strong>, em relação aos dados por ele tratados, a
        qualquer momento e mediante requisição:
      </p>
      <ul>
        <li>confirmação da existência de tratamento;</li>
        <li>acesso aos dados;</li>
        <li>correção de dados incompletos, inexatos ou desatualizados;</li>
        <li>
          anonimização, bloqueio ou eliminação de dados desnecessários,
          excessivos ou tratados em desconformidade com o disposto na Lei nº
          13.709;
        </li>
        <li>portabilidade dos dados, mediante requisição expressa;</li>
        <li>
          eliminação dos dados pessoais tratados com o consentimento do{" "}
          <strong>CANDIDATO</strong>, exceto nas hipóteses previstas no artigo
          16 da Lei nº 13.709;
        </li>
        <li>
          informação sobre a possibilidade de não fornecer consentimento e sobre
          as consequências da negativa;
        </li>
        <li>
          revogação do consentimento, nos termos do § 5º do artigo 8º da Lei nº
          13.709.
        </li>
      </ul>
      <br></br>
      <p className="text-justify">
        <strong>DIREITO DE REVOGAÇÃO DO CONSENTIMENTO</strong>
      </p>
      <p>
        Conforme disposto acima, este consentimento poderá ser revogado pelo{" "}
        <strong>CANDIDATO</strong>, a qualquer momento, mediante solicitação a{" "}
        <strong>G M COMÉRCIO DE MEDICAMENTOS</strong>.
      </p>
      <p>Ciente e de acordo.</p>
    </div>
  );
}
